<template>
  <div>
    <v-card class="pa-8 print-no-spacing">
      <v-row justify="center">
        <v-col class="text-left">
          <p class="text-h5">{{ $t("global.list_filter") }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="text-left">
          <AgentFilter
            v-model="agentFilter"
            data-cy="matchesFilters"
            :mine-text="$t('listing.job_applications.filters.mine')"
            :all-text="$t('listing.job_applications.filters.all')"
            @input="handleAgentFilterChanged"
          ></AgentFilter>
        </v-col>
        <v-col cols="12" md="6" class="text-left">
          <v-text-field
            v-model="search"
            data-cy="input-search"
            append-icon="mdi-magnify"
            :label="$t('listing.job_applications.search')"
            single-line
            hide-details
            @input="handleSearchChanged"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pa-8 mt-4 fill-height">
      <v-row no-gutters class="d-print-none">
        <v-col>
          <DataListTitle
            :title="`${totalItems} ${$t('listing.job_applications.registered.plural')}`"
            :is-loading="loading"
          ></DataListTitle>
        </v-col>
        <v-spacer />
      </v-row>
      <div>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          :footer-props="{
            'items-per-page-text': $t('listing.job_applications.pagination'),
            'items-per-page-options': itemsPerPageOptions
          }"
          class="print-hide-last-column"
          mobile-breakpoint="811"
          @update:options="handleTableOptionUpdated"
        >
          <template v-slot:item.rate="{ value }">
            <span>{{ value }} %</span>
          </template>
          <template v-slot:item.candidate.birthdate="{ value }">
            <span>{{ formatDate(value) }}</span>
          </template>
          <template v-slot:item.updated_at="{ value }">
            <span>{{ formatDate(value) }}</span>
          </template>
          <template v-slot:item.availability="{ value }">
            <span>{{ formatDate(value) }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="py-2 nowrap">
              <v-btn color="default" small @click="showJobApplicationActivities(item.id)">
                {{ $t('activities.explicit') }}
              </v-btn>
              <v-btn
                color="primary"
                class="ml-3"
                small
                @click="$emit('show-job-application-details', item.id)"
              >
                {{ $t('listing.job_applications.details') }}
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import AgentFilter from "@/components/common/AgentFilter.vue";
import DataListTitle from '@/components/layout/DataListTitle.vue'
import DataTablesMixin from '@/mixins/dataTablesMixin'
import {mapActions, mapState} from 'vuex'
import RouterMixin from '@/mixins/routerMixin'
import DatesMixin from '@/mixins/datesMixin'
import SearchFilterMixin from '@/mixins/searchFilterMixin'
import {filterManagement} from "@/services/filters"
import AgentFilterMixin from '@/mixins/agentFilterMixin'

export default {
  name: 'JobApplicationsList',
  components: {DataListTitle, AgentFilter },
  mixins: [DataTablesMixin, RouterMixin, DatesMixin, SearchFilterMixin, AgentFilterMixin],
  props: {},
  data() {
    return {
      isNavigatingBack: false,
      headers: [
        {
          text: this.$t('listing.headers.id'),
          value: 'id'
        },
        {
          text: this.$t('listing.job_applications.headers.last_name'),
          value: 'candidate.last_name',
          align: 'start'
        },
        {
          text: this.$t('listing.job_applications.headers.first_name'),
          value: 'candidate.first_name'
        },
        {
          text: this.$t('listing.job_applications.headers.birthdate'),
          value: 'candidate.birthdate'
        },
        {
          text: this.$t('listing.job_applications.headers.rate'),
          value: 'rate'
        },
        {
          text: this.$t('listing.job_applications.headers.updated_at'),
          value: 'updated_at'
        },
        {
          text: this.$t('listing.job_applications.headers.availability'),
          value: 'availability'
        },
        {
          value: 'actions',
          align: 'right'
        }
      ]
    }
  },
  computed: {
    ...mapState('jobApplications', ['storedFilters']),
  },
  methods: {
    ...mapActions('jobApplications', ['getJobApplications']),
    showJobApplicationActivities(jobApplicationId) {
      this.routerPush(`/jobApplications/${jobApplicationId}/activities`)
    },
    getDataFromApi () {
      this.loading = true

      const args = {
        ...filterManagement.getTableOptionsFromUrlQuery(this.$route),
        search: filterManagement.getSearchFromUrlQuery(this.$route),
        mine: filterManagement.getAgentFilterFromUrlQuery(this.$route)
      }
      args.status__not = 'ARCHIVED'

      this.getJobApplications(args).then(data => {
        this.items = data.items
        this.totalItems = data.count
        this.loading = false
      })
    },
  }
}
</script>

<style scoped></style>
